body {
  background-color: white;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
}

@media print {
  #App-header {
    height: 0;
    display: none !important;
    z-index: 0 !important;
  }

  #backButton {
    display: none !important;
  }

  #printButton {
    display: none !important;
  }

  #App-main {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
  }

  #recipeContainer {
    width: 600px !important;
    margin-top: 10px !important;
  }

  #backgroundStripes {
    display: none !important;
  }
}

#App-header {
  position: fixed;
  z-index: 1;
  background-color: white;
  height: 120px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  border-bottom: 2px solid rgb(203, 203, 203);
}

.linkHome {
  /* margin-left: 10%; */
  height: 100%;
  width: 100%;
  color: teal;
  text-decoration: none;
  display: flex;
  justify-content: center;
}

#logoName {
  margin-left: 20px;
}

#logoImg {
  width: 30%;
  min-width: 250px;
  max-width: 360px;
  max-height: 100%;
  object-fit: cover;
}

#searchContainer {
  font-family: "Voltaire", sans-serif;
  letter-spacing: 0.75px;
  position: absolute;
  right: 5%;
  z-index: 10;
  height: fit-content;
  width: 25%;
  background-color: rgb(244, 244, 244);
  max-width: 300px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  border-radius: 5px;
}

#searchBar {
  width: 85%;
}

#catagoryHeader {
  position: absolute;
  width: 100%;
  text-align: left;
}

#catagoryButton {
  background-color: #282c34;
  border: 0;
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: 25px;
  margin-right: 50px;
}

.filterButtonContainer {
  font-family: "Roboto", sans-serif;
  display: flex;
  gap: 10px;
  position: relative;
  margin-left: 5%;
  height: 40px;
  bottom: -2px;
  color: #868686;
}

.filterButton {
  font-family: "Voltaire", sans-serif;
  letter-spacing: 1.25px;
  position: relative;
  font-size: 23px;
  background-color: rgb(244, 244, 244);
  padding: 4px 10px;
  border-top: 2px solid transparent;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 10%;
  height: 28px;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}

.activeFilterButton {
  border: 2px solid rgba(203, 203, 203);
  border-bottom: 2px solid rgb(221, 234, 234);
  background-color: rgb(221, 234, 234);
}

.font {
  font-family: "Dancing Script", cursive;
  font-size: 40px;
}

#App-main {
  position: absolute;
  background-color: white;
  z-index: -1;
  top: 100px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#backgroundStripes {
  position: fixed;
  z-index: -10;
  height: max-content;
  overflow: hidden;
}

.stripe {
  height: 30vh;
  width: 200vw;
}

.teal {
  background-color: rgba(19, 115, 115, 0.16);
  height: 28vh;
}

.red {
  background-color: rgba(227, 165, 140, 0.1);
}

.grey {
  background-color: rgb(244, 244, 244);
}

#searchStrip {
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  height: 65px;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
}

#catagoryTitle {
  font-size: 30px;
  font-family: "Phudu", cursive;
  color: #5f5f5f;
  display: flex;
  width: 100%;
  height: 40px;
  margin-top: 30px;
  justify-content: center;
}

#backButton {
  width: 30px;
  height: 30px;
  left: 0;
  border-radius: 5px;
  text-align: center;
  font-size: 30px;
  position: fixed;
  left: 1%;
  top: 150px;
}

#backButton:hover {
  cursor: pointer;
  background-color: #d5d5d5;
}

.loadCircle {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hidden {
  display: none;
}

.noOverflow {
  height: 0;
  overflow: none;
}

#cardContainer {
  position: absolute;
  z-index: -1;
  width: 80%;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#cardContainer::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

#topBorder {
  width: 100%;
  height: 90px;
}

.card {
  position: relative;
  flex: 1 1 auto;
  width: 20%;
  aspect-ratio: 1 / 1;
  min-width: 180px;
  max-width: 250px;
  margin: 5px;
  cursor: pointer;
}

.card img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card div {
  font-family: "Voltaire", sans-serif;
  letter-spacing: 1.5px;
  font-weight: 100;
  font-size: 18px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
  background-color: rgb(57, 120, 120, 0.9);
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card p {
  margin-left: 5px;
  margin-right: 5px;
  text-align: left;
  line-height: 18px;
}

#create-main {
  background-color: white;
  z-index: -1;
  width: 95%;
  height: fit-content;
  margin-top: 100px;
  border-radius: 5px;
  box-shadow: 0px 0px 8px #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}

#recipeInfo {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

h1 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 100;
  margin-top: 35px;
  color: teal;
  text-align: center;
  font-size: 25px;
}
.spacer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
  width: 100%;
  overflow: hidden;
}

.tag {
  font-family: sans-serif;
  font-weight: 200;
  margin: 0;
  font-size: 20px;
}

.recipeInfo {
  background-color: rgb(246, 246, 246);
  border: 0;
  font-size: 25px;
  outline: none;
}

#createContainer {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#addIngredients {
  color: teal;
  border-radius: 10px;
  width: 90%;
  min-width: 300px;
  font-size: 40px;
}

.createText {
  width: 100%;
  height: 300px;
  border: 2px solid teal;
  box-shadow: 0px 0px 5px #282c34;
  border-radius: 10px;
  background-color: white;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: #282c34;
  outline: none;
  display: flex;
  flex-direction: column;
}

#addDirections {
  color: teal;
  border-radius: 10px;
  width: 90%;
  min-width: 300px;
  font-size: 40px;
}

#addPhoto {
  color: teal;
  border-radius: 10px;
  width: 30%;
  min-width: 300px;
  font-size: 40px;
}

#submitButton {
  background-color: #282c34;
  color: white;
  width: 200px;
  border-radius: 10px;
  font-size: 30px;
  margin: 10px;
  margin-bottom: 40px;
}

#recipeDisplay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#recipeContainer {
  position: relative;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  z-index: 0;
  font-size: 20px;
  margin-top: 150px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 85%;
  max-width: 850px;
  border-radius: 10px;
  box-shadow: 0px 0px 4px black;
}

#printButton {
  margin-left: 10px;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  text-align: center;
  font-size: 30px;
  position: absolute;
  right: 20px;
  color: #5f5f5f;
  border-radius: 10px;
  top: 0px;
}

#printButton:hover {
  cursor: pointer;
  background-color: #d5d5d5;
}

#recipeImg {
  border-radius: 5px;
  margin-top: 30px;
  height: 230px;
  /* width: 230px; */
  object-fit: cover;
}
#recipeContainer h2 {
  padding: 0px 100px;
  text-align: center;
  margin-bottom: 10px;
}

#recipeContainer div {
  margin-top: 20px;
  border-radius: 5px;
  height: fit-content;
  width: 80%;
  max-width: 700px;
}

#ingredients {
  columns: 2;
}

#recipeContainer ul,
ol {
  font-size: 17px;
  margin-top: 5px;
}

#recipeContainer h3 {
  margin: 0 50px;
  font-size: 17px;
  font-weight: 400;
}

#recipeContainer div h3 {
  border-bottom: 1px solid #282c34;
  margin: 0;
  font-size: 22px;
  font-weight: 500;
}

#footerImg {
  margin: 0;
  height: 100px;
  width: 200px;
  object-fit: cover;
}

#fileId {
  position: relative;
  z-index: 1;
  background: transparent;
}

#pasteImage {
  /* border: 2px solid #282c34; */
  border: 0;
  border-top: 2px solid grey;
  background: transparent;
  font-size: 30px;
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  user-select: none;
  text-align: center;
  color: grey;
}

#preview {
  position: absolute;
  z-index: -1;
  height: 100%;
  width: auto;
  object-fit: contain;
}

.foot {
  height: 100px;
  width: 100%;
  color: white;
  background-color: white;
  border: 0;
}

#footer {
  bottom: 0;
  z-index: 10;
  background-color: teal;
  color: white;
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 660px) {
  body {
    overflow-y: none;
  }

  #App-header {
    position: fixed;
    z-index: 1;
    background-color: white;
    height: 120px;
    width: 100%;
    display: flex;
    justify-content: centerf;
    align-items: flex-end;
  }

  #logoImg {
    min-width: 0;
    width: 250px;
    height: 75px;
    margin-top: 7px;
  }

  .filterButtonContainer {
    font-family: "Roboto", sans-serif;
    display: flex;
    gap: 5px;
    position: relative;
    margin-left: 5px;
    height: 30px;
    bottom: -2px;
    color: #868686;
  }

  .filterButton {
    font-size: 18px;
    padding-top: 2px;
    height: 20px;
    width: fit-content;
  }

  #searchContainer {
    font-family: "Voltaire", sans-serif;
    letter-spacing: 0.75px;
    position: absolute;
    right: 5px;
    z-index: 10;
    height: fit-content;
    width: 30%;
    background-color: rgb(244, 244, 244);
    max-width: 300px;
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    border-radius: 5px;
    border: transparent solid 2px;
  }

  #searchContainer:focus-within {
    width: 200px;
    border: rgb(203, 203, 203) solid 2px;
  }

  #searchBar {
    width: 100%;
  }

  #App-main {
    position: absolute;
    background-color: white;
    z-index: -1;
    top: 0px;
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }

  #catagoryTitle {
    font-size: 25px;
    font-family: "Phudu", cursive;
    color: #5f5f5f;
    display: flex;
    width: 100%;
    height: 20px;
    margin-top: 130px;
    justify-content: center;
  }

  #backgroundStripes {
    overflow: none;
  }

  #cardContainer {
    position: absolute;
    z-index: -1;
    width: 92%;
    height: auto;
    top: 100px;
    bottom: 0px;
    padding-bottom: 100px;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .card {
    min-width: 150px;
    max-width: 150px;
    height: 150px;
  }

  .card div {
    height: fit-content;
  }

  .card p {
    margin: 5px;
  }

  #recipeContainer h2 {
    padding: 0px 10px;
  }

  #recipeContainer div {
    margin-top: 20px;
    border-radius: 5px;
    height: fit-content;
    width: 95%;
    max-width: 700px;
  }

  #recipeContainer {
    width: 95%;
  }

  #backButton {
    top: 135px;
    left: 5px;
  }

  #printButton {
    margin-left: 10px;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    text-align: center;
    font-size: 25px;
    position: absolute;
    right: 5px;
    color: #5f5f5f;
    border-radius: 10px;
    top: 0px;
  }
  .recipeInfo {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    font-size: 15px;
    margin-left: 4px;
    width: 100%;
    height: 23px;
    outline: none;
  }

  .tag {
    text-align: left;
    font-family: Helvetica, sans-serif;
    font-weight: 400;
    color: #282c34;
    width: 50%;
    font-size: 16px;
    padding-top: 5px;
  }

  #createContainer {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #addIngredients {
    color: teal;
    margin-left: 0px;
    border-radius: 10px;
    width: 100%;
    min-width: 0;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #addIngredients p {
    width: 90%;
    font-family: Helvetica, sans-serif;
    font-weight: 400;
    font-size: 18px;
  }

  .createText {
    height: 300px;
    width: 90%;
    border: 2px solid teal;
    box-shadow: 0px 0px 5px #282c34;
    border-radius: 10px;
    background-color: white;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    color: #282c34;
    outline: none;
    display: flex;
    flex-direction: column;
  }

  #addDirections {
    font-family: Helvetica, sans-serif;
    font-weight: 400;
    color: teal;
    margin-left: 0;
    border-radius: 10px;
    width: 100%;
    min-width: 0;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 18px;
  }

  #addDirections p {
    width: 90%;
  }

  #pasteImage::placeholder {
    font-size: 20px;
  }

  #submitButton {
    background-color: #747474;
    color: white;
    width: 200px;
    height: 35px;
    border-radius: 10px;
    border: 0;
    font-size: 30px;
    margin: 15px;
    margin-bottom: 20px;
    cursor: pointer;
  }
}
