.tabs li { 
    /* Makes a horizontal row */
    float: left; 
      
    /* So the psueudo elements can be
       abs. positioned inside */
    position: relative; 
  }
  .tabs a { 
    /* Make them block level
       and only as wide as they need */
    float: left; 
    margin-left: 5px;
    padding: 6px; 
    text-decoration: none;
    width: 12vw;
    text-align: center;
    border-top: 2px solid rgba(203, 203, 203, 0);
    border-left: 2px solid rgba(203, 203, 203, 0);
    border-right: 2px solid rgba(203, 203, 203, 0);
    
    
    /* Default colors */ 
    color: grey;
    background: rgb(244, 244, 244);
    
    /* Only round the top corners */
    -webkit-border-top-left-radius: 15px;
    -webkit-border-top-right-radius: 15px;
    -moz-border-radius-topleft: 15px;
    -moz-border-radius-topright: 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px; 
  }
  .tabs .active {
    /* Highest, active tab is on top */
    z-index: 3;
    
  }
  .tabs .active a { 
    /* Colors when tab is active */
    background: rgb(221,234,234); 
    color: (244, 244, 244);
    border-top: 2px solid rgb(203, 203, 203);
    border-left: 2px solid rgb(203, 203, 203);
    border-right: 2px solid rgb(203, 203, 203);
    border-bottom: 2px solid rgb(221,234,234);
  }
 


  /* Squares */

