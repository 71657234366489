#searchInput {
  display: flex;
  height: 30px;
  width: 100%;
  font-size: 15px;
  outline: none;
  border: 0;
  padding-bottom: 3px;
  margin-left: 5px;
  background-color: transparent;
  border-radius: 5px;
  color: #5e5e5e;
  text-overflow: ellipsis;
}

#searchInput::placeholder {
  font-family: 'Voltaire', sans-serif;
  letter-spacing: 0.75px;
  color: #5e5e5e;
  opacity: 0.5;
}

#searchInput:focus {
  font-size: 15px;
}

@media screen and (max-width: 768px) {
  #searchInput {
    height: 28px;
    padding: 0;
    width: 75px;
    text-overflow: ellipsis;
  }

  #searchInput:focus {
    width: 165px
  }
}

#searchIcon {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: teal;
}

#searchContents {
  position: absolute;
  z-index: 11;
  width: 100%;
  color: #5e5e5e;
  margin-top: 3px;
  box-shadow: 0px 0px 10px grey;
}

.searchItem {
  font-family: 'Voltaire', sans-serif;
  letter-spacing: 0.1px;
  background-color: white;
  border-left: rgb(255, 255, 255) solid 2px;
  border-top: rgb(255, 255, 255) solid 2px;
  border-right: rgb(255, 255, 255) solid 2px;
  border-bottom: white solid 2px;
  border-radius: 5px;
  font-size: 90%;
  height: fit-content;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.searchItem:hover {
  cursor: pointer;
  background-color: #ededed;
}

.searchImg {
  height: 37px;
  width: 45px;
  object-fit: cover;
}

.searchLabel {
  color: teal;
  margin: 0;
  margin-left: 5px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}
